<template>
	<div>
		
							
		<div class="ns-body layui-body" style="left: 100px; top: 60px;">
			<!-- 内容 -->
			<div class="ns-body-content">
				<!-- 四级导航 -->



				<div class="ns-survey">
					<div class="ns-survey-left">
						<div class="ns-survey-item">
							

							<!-- 概况 -->
							<div class="layui-card ns-survey-info ns-card-common">
								<div class="layui-card-header" style="font-size: 14px;display: flex;align-items: center;justify-content: space-between;">
									<div>
										<span class="ns-card-title">概况</span>
										
									</div>
									
									<div class="dis" style="margin-right: 10px;">
										<div class="ns-card-title" style="color: #aaa;margin-right: 10px;">小程序页面显示</div>
										<el-switch
										  @change="showPageSwitchChange"
										  v-model="isShowAppPage"
										 >
										</el-switch>
									</div>
								</div>
								<div class="layui-card-body">
									
									<!-- 今日订单数start -->
									<div class="ns-survey-detail-con">
										<div class="ns-survey-detail-aco">
											今日订单数
											
											<!-- 提示start >
											<div class="ns-prompt-block" style="left: 100px;top: 20px;">
												<div class="ns-prompt">
													<el-tooltip style="margin-left: 10px;" class="item" effect="dark"
														content='今日客户下单后会参与 "今日订单数" 统计，已完成的订单会参与 "订单总数" 统计' placement="top-start">
														<i class="el-icon-info required ns-growth"></i>
													</el-tooltip>
												</div>
											</div>
											<! 提示end -->
											
										</div>
										<p class="ns-survey-detail-num ns-text-color">{{ indexData.dayData?parseInt(indexData.dayData.order_day):'0' }}</p>
										
										<div class="ns-survey-detail-split"></div>
										<div class="ns-survey-detail-total">
											<span>订单总数</span>
											<span>{{ parseInt(indexData.orderTotal)}}</span>
										</div>
									
									</div>
									<!-- 今日订单数end -->
									
									
									<!-- 今日销售额start -->
									<div class="ns-survey-detail-con">
										<p class="ns-survey-detail-aco">今日销售额(元)</p>
										<p class="ns-survey-detail-num ns-text-color">{{indexData.dayData?indexData.dayData.sale_day:'0'}}</p>
										
										<div class="ns-survey-detail-split"></div>
										<div class="ns-survey-detail-total">
											<span>销售总额</span>
											<span>{{indexData.orderPriceTotal}}</span>
										</div>
									</div>
									<!-- 今日销售额end -->
									
									<!-- 今日新增客户数start -->
									<div class="ns-survey-detail-con">
										<p class="ns-survey-detail-aco">今日新增客户数</p>
										<p class="ns-survey-detail-num ns-text-color">{{ indexData.dayData?parseInt(indexData.dayData.customer_day):'0' }}</p>
										
										<div class="ns-survey-detail-split"></div>
										<div class="ns-survey-detail-total">
											<span>客户总数</span>
											<span>{{ parseInt(indexData.userTotal) }}</span>
										</div>

									</div>
									<!-- 今日新增客户数end -->
									
									
									<!-- 未知start -->
									<div class="ns-survey-detail-con">
										<p class="ns-survey-detail-aco">暂无</p>
										<p class="ns-survey-detail-num ns-text-color">0</p>
										
										<div class="ns-survey-detail-split"></div>
										<div class="ns-survey-detail-total">
											<span>暂无</span>
											<span>0</span>
										</div>
									
									</div>
									<!-- 未知end -->
									

								</div>
							</div>

						</div>

						<div class="statistics-wrap">
							<div class="item" style="flex:1">
								
								<div class="flex-box" style="background: rgba(255, 69, 68, 0.1);"
									@click="go('/orderList?state=1')">
									<h5 class="title">待发货订单</h5>
									<div class="num">{{indexData.daiFaHuoTotal}}</div>
								</div>
								<div class="flex-box" style="background: rgba(80, 130, 255, 0.1);"
									@click="go('/orderList?state=5')">
									<h5 class="title">申请退款订单</h5>
									<div class="num">{{indexData.shenQingTuiKuanTotal}}</div>
								</div>
							</div>


							<div class="item" style="flex:1">
								
								<div class="flex-box" style="background: rgba(255, 125, 68, 0.1);"
									@click="go('/goodsList?kuCunYuJing=1')">
									<h5 class="title">库存预警</h5>
									<div class="num">{{indexData.goodsBuZuNum}}</div>
								</div>
								<div class="flex-box" style="background: rgba(60, 188, 66, 0.1);"
									@click="go('/goodsList')">
									<h5 class="title">出售中商品</h5>
									<div class="num">{{indexData.goodsTotal}}</div>
								</div>
							</div>

							<div class="item" style="flex:1">
								
								<div class="flex-box" style="background: rgba(255, 174, 68, 0.1);"
									>
									<h5 class="title">暂无</h5>
									<div class="num">0</div>
								</div>
								<div class="flex-box" style="background: rgba(38, 189, 216, 0.1)"
									>
									<h5 class="title">暂无</h5>
									<div class="num">0</div>
								</div>
							</div>

							
						</div>

						<div class="echarts-wrap">
							<!-- 近七天订单数echarts图表 start -->
							<div class="echarts-order">
								<h5>近七天订单数(个)</h5>
								<div id="orderEcharts" ref="orderEcharts"
									style="width: 100%; height: 400px; -webkit-tap-highlight-color: transparent; user-select: none; position: relative;">
								</div>
							</div>
							<!-- 近七天订单数echarts图表end -->

							<!-- 销售额echarts图表start -->
							<div class="echarts-money">
								<h5>销售额(元)</h5>
								<div id="moneyEcharts" ref="moneyEcharts"
									style="width: 100%; height: 400px; -webkit-tap-highlight-color: transparent; user-select: none; position: relative;">
								</div>
							</div>
							<!-- 销售额echarts图表end -->
						</div>

					


					</div>
				</div>

			</div>


			<!-- 版权信息 -->
			<div class="ns-footer">
				<div class="ns-footer-img">
					<img :src="icon" style="width: 50px;height: 50px;">
					
				</div>
				<!-- <div style="margin-left: 10px;font-size: 13px;">MUE 沐懿</div> -->
				<!--<p>山西牛之云<a href="https://www.niushop.com">&nbsp;&nbsp;备案号晋ICP备16001360号-2</a></p>-->
				<!--<a class="gov-box" href=><img src="https://uniapp.v4.niuteam.cn/app/home/view/public/img/gov_record.png" alt="">公安备案121212121</a>-->
			</div>

		</div>
		
		
		<!-- 全屏透明的loading加载start -->
		<div id="pageLoading" v-if="loading" style="position: fixed;top:0;left: 0;width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;z-index: 999999;">
			<i class="el-icon-loading" style="font-size: 25px;"></i>
		</div>
		<!-- 全屏透明的loading加载end -->

		
		
	</div>
</template>

<script>
	
	import { ajax } from '../../util/ajax.js';
	export default {
		name: '',
		components: {
		
		},
		data() {
			return {
				isShowAppPage:false,
				icon: require('@/assets/logo.png'),
				indexData:'',
				loading: false,
				
		
				
			}
		},
		mounted() {
			
		
			this.initData();
			
			


		},
		methods: {
			
			async initData(){
			
				this.loading = true;
				let res = await ajax('/getIndexData', 'POST',{});
				let otherInfoRes = await ajax('/getOther', 'POST',{});
				this.loading = false;
			
				this.indexData = res.data.data;
				
				this.isShowAppPage = otherInfoRes.data.data.info.isShowAppPage?true:false;
				
				
			
			//图表start
			setTimeout(() => {
				
				let orderEcharts = this.$echarts.init(this.$refs.orderEcharts);
				let moneyEcharts = this.$echarts.init(this.$refs.moneyEcharts);

				orderEcharts.setOption({
					xAxis: {
						type: 'category',
						data:  this.indexData.orderBy7DayJsonObj.dayTxtArr,  //['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
					},
					tooltip: {
						trigger: 'axis'
					  },
					yAxis: {
						type: 'value'
					},
					series: [{
						data:  this.indexData.orderBy7DayJsonObj.valArr,  //[150, 230, 224, 218, 135, 147, 260],
						type: 'line'
					}]
				});

				moneyEcharts.setOption({
					xAxis: {
						type: 'category',
						data: this.indexData.orderBy7DayJsonObj.dayTxtArr, //['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
					},
					tooltip: {
						trigger: 'axis'
					  },
					yAxis: {
						type: 'value'
					},
					series: [{
						data: this.indexData.orderBy7DayJsonObj.priceArr,  //[150, 230, 224, 218, 135, 147, 260],
						type: 'line'
					}]
				});
			
			}, 1000)
			//图表end
				
				
			
			},
			
			//显示隐藏小程序页面
			async showPageSwitchChange(bool){
				this.isShowAppPage = bool;
				let val = bool?1:0;
				this.loading = true;
				let res = await ajax('/editIsShowAppPage', 'POST',{val});
				this.loading = false;
				
				
			},
			
			go(url) {
				this.$router.push({
					path: url
				});
			},
			
			
			
			
		}
	}
</script>

<style scoped>
	.layui-layout-admin .layui-body .ns-body-content {
		min-width: 1200px;
		padding: 0 !important;
		margin: 0px !important;
	}

	.ns-card-common {
		margin-top: 0px !important;
	}

	/* @media screen and (max-width: 1270px) {
		.ns-survey-info .layui-card-body .ns-survey-detail-con {
			flex-basis: 33% !important;
		}
	} */


	.expire-hint {
		display: flex;
		align-items: center;
		padding: 15px 30px;
		height: 75px;
		line-height: 75px;
		background-color: #fff9f5;
		box-sizing: border-box;
	}

	.expire-hint .expire-logo {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 15px;
		width: 40px;
		height: 40px;
	}

	.expire-hint .expire-logo img {
		max-height: 100%;
		max-width: 100%;
	}

	.expire-hint .expire-center {
		line-height: 1;
	}

	.expire-hint .expire-center h3 {
		font-size: 15px;
		font-weight: 700;
	}

	.expire-hint .expire-center p {
		margin-top: 10px;
		color: #5d5c5c;
	}

	.expire-hint .expire-center p span:nth-of-type(2) {
		margin-left: 15px;
	}

	.expire-hint .expire-center h3 a {
		font-size: 12px;
		font-weight: normal;
		margin-left: 5px;
		cursor: pointer;
	}


	.ns-survey {
		display: flex;
		padding: 30px 40px;
		background: #f8f8f8;
		padding-top: 0px;
	}


	.ns-card-common .ns-card-title {
		font-weight: 500;
	}

	.ns-card-common .ns-card-sub {
		margin-left: 12px;
		color: #B7B8B7;
	}

	.ns-card-common .layui-card-header {
		border: 0;
		padding: 0;
		height: auto;
		line-height: 16px;
		padding: 20px 0;
	}

	.ns-card-common .layui-card-body {
		padding: 0;
	}

	.ns-item-block-parent .ns-item-block {
		background-color: transparent;
	}

	.ns-item-block-parent .ns-item-pic {
		overflow: hidden;
		border-radius: 5px;
	}

	.ns-item-block-parent {
		padding: 0;
		background: #f8f8f8;
	}


	.ns-survey-item .ns-survey-info {
		flex: 1;
		background: #f8f8f8;
	}

	.ns-survey-item {
		display: flex;
		justify-content: space-between;
	}

	.ns-survey-item .ns-survey-shop {
		display: flex;
		width: 350px;
		padding: 20px;
		box-sizing: border-box;
		border: 1px solid #f1f1f1;
	}

	.ns-survey-item .ns-survey-shop .ns-item-pic {
		display: flex;
		width: 65px;
		height: 65px;
		justify-content: center;
		align-items: center;
	}

	.ns-survey-item .ns-survey-shop .ns-item-pic img {
		max-width: 100%;
		max-height: 100%;
	}

	.ns-survey-item .ns-survey-shop .ns-surver-shop-detail {
		margin-left: 15px;
		flex: 1;
	}

	.ns-survey-item .ns-survey-shop .ns-survey-shop-name {
		font-size: 16px;
		font-weight: 600;
		color: rgba(38, 38, 38, 1);
		height: 25px !important;
		line-height: 25px !important;
		margin-bottom: 10px;
	}

	.ns-survey-item .ns-survey-shop .ns-survey-shop-name span {
		border: 1px solid #999;
		padding: 1px 2px;
		line-height: 1;
		color: #999;
		font-size: 12px;
		margin-left: 10px;
		border-radius: 2px;
		cursor: pointer;
	}

	.ns-survey-item .ns-survey-shop .ns-surver-shop-detail p {
		line-height: 30px;
		color: #595959;
	}

	.ns-survey-item .ns-survey-shop .ns-surver-shop-detail p:nth-of-type(2) {
		font-size: 12px;
		color: #B7B8B7;
	}


	.ns-survey-info {
		margin: 0;
	}

	.ns-survey-info .layui-card-body {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.ns-survey-info .layui-card-body .ns-survey-detail-con {
		display: inline-block;
		width: calc((100% - 90px) / 4);
		margin-right: 30px;
		margin-bottom: 30px;
		background-color: #FFF;
		padding: 0 20px;
		box-sizing: border-box;
		position: relative;
	}

	.ns-survey-info .layui-card-body .ns-survey-detail-con:last-child {
		margin-right: 0;
	}

	.ns-survey-info .layui-card-body .ns-survey-detail-aco {
		font-size: 16px;
		line-height: 18px;
		margin-top: 20px;
		font-weight: 400;
	}

	.ns-survey-info .layui-card-body .ns-survey-detail-num {
		margin: 10px 0;
		font-size: 36px;
		font-weight: 400;
		margin-top: 48px;
	}

	.ns-survey-detail-rate {
		font-size: 14px;
		line-height: 30px;
		display: flex;
		align-items: center;
		margin-top: 25px;
	}

	.ns-survey-detail-rate div i {
		margin-left: 5px;
	}

	.ns-survey-detail-rate div:nth-child(2) {
		margin-left: 25px;
	}

	.layui-icon-triangle-d {
		font-size: 12px;
		display: inline-block;
		transform: scale(0.8, 0.9);
		-webkit-transform: scale(0.8, 0.9);
		/*å…¼å®¹-webkit-å¼•æ“Žæµè§ˆå™¨*/
		-moz-transform: scale(0.8, 0.9);
		/*å…¼å®¹-moz-å¼•æ“Žæµè§ˆå™¨*/
		color: #5ED300;
	}

	.layui-icon-increase {
		transform: scale(0.8, 0.9) rotate(180deg);
		-ms-transform: rotate(180deg);
		/* IE 9 */
		-moz-transform: scale(0.8, 0.9) rotate(180deg);
		/* Firefox */
		-webkit-transform: scale(0.8, 0.9) rotate(180deg);
		/* Safari å’Œ Chrome */
		-o-transform: rotate(180deg);
		/* Opera */
	}

	.layui-icon-triangle-d.is-null {
		display: none;
	}

	.ns-survey-detail-split {
		width: 100%;
		height: 1px;
		background-color: #FFFFFF;
		margin-top: 25px;
	}

	.ns-survey-detail-total {
		display: flex;
		justify-content: space-between;
		margin: 18px 0 15px;
		font-size: 14px;
		line-height: 16px;
	}

	.ns-prompt-block {
		position: absolute;
		top: 18px;
		right: 20px;
		font-size: 12px;
		cursor: pointer;
	}

	.ns-prompt-block .ns-prompt {
		width: auto;
		height: auto;
		line-height: unset;
		text-align: right !important;
	}

	.ns-prompt-block .ns-prompt-box {
		top: 40px;
		left: -53px;
		width: 150px;
		text-align: center;
	}

	.ns-prompt-block .ns-prompt-box:before,
	.ns-prompt-block .ns-prompt-box:after {
		left: 62px;
		top: unset;
	}

	.ns-prompt-block .ns-prompt-box:before {
		border-bottom-color: #e4e4e4;
		border-right-color: transparent;
		top: -25px;
	}

	.ns-prompt-block .ns-prompt-box:after {
		border-bottom-color: #FFFFFF;
		border-right-color: transparent;
		top: -23px;
	}

	.ns-survey-yesterday-btn {
		font-size: 12px;
		width: 44px;
		height: 22px;
		padding: 0;
		line-height: 22px;
	}

	/* .ns-survey-info .layui-card-body .ns-survey-detail-yesterday {
		font-size: 14px;
		font-weight: 400;
		color: rgba(175, 175, 175, 1);
	} */

	/*  */
	.ns-survey-right .layui-card {
		border: 1px solid #f1f1f1;
		box-shadow: inherit;
	}

	.ns-survey-right .layui-card .layui-card-body {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.ns-shop-state {
		margin: 30px 40px 0;
		padding: 10px;
		padding-right: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 35px;
		background-color: rgba(255, 129, 67, 0.1);
		color: #fff;
		border: 1px solid red;
	}

	.ns-shop-state p {
		color: #666;
	}

	.ns-shop-state p i {
		margin-right: 6px;
	}

	.ns-shop-state button {
		border: none;
		background-color: transparent;
		cursor: pointer;
	}


	.common-functions {
		width: 100%;
	}

	.common-functions-item {
		display: inline-block;
		width: 215px;
		background-color: #FFF;
		text-align: center;
		margin-right: 10px;
		margin-bottom: 15px;
		padding: 24px 20px;
		box-sizing: border-box;
	}

	.common-functions-item:last-child {
		margin-right: 0;
	}

	.common-functions-box .ns-item-pic {
		display: inline-block;
		width: 40px;
		height: 40px;
		text-align: center;
		line-height: 40px;
	}

	.common-functions-box .ns-item-pic img {
		max-width: 100%;
		max-height: 100%;
	}

	.common-functions-box .ns-item-content-title {
		font-size: 14px;
		line-height: 14px;
		margin-top: 17px;
	}

	.common-functions-box .ns-item-content-desc {
		font-size: 12px;
		color: #666666;
		line-height: 12px;
		margin-top: 9px;
		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.addon-tool {
		width: 100%;
		display: flex;
	}

	.addon-tool .ns-card-common {
		width: 100%;
		background: #f8f8f8;
	}

	.addon-tool .addon-tool-item {
		width: 270px;
		padding: 5px 0 5px 50px;
		background-color: #FFF;
		box-sizing: border-box;
		margin-right: 10px;
		margin-bottom: 10px;
	}

	.ns-survey-left {
		flex: 1;
	}

	.ns-survey-left>.ns-card-common {
		margin-top: 40px;
		background: #f8f8f8;
	}

	/* å›¾è¡¨ */
	.echarts-wrap {
		display: flex;
		justify-content: space-between;
	}

	.echarts-wrap>div {
		width: 49%;
		background-color: #FFF;
	}

	.echarts-wrap>div h5 {
		font-size: 16px;
		padding-left: 20px;
		padding-top: 20px;
	}

	.statistics-wrap {
		display: flex;
	}

	.statistics-wrap .item {
		display: inline-block;
		width: calc((100% - 90px) / 4);
		margin-right: 30px;
		margin-bottom: 30px;
		display: flex;
	}

	.statistics-wrap .item:last-child {
		margin-right: 0;
	}

	.statistics-wrap .item .flex-box {
		flex: 1;
		background: #f5f5f5;
		height: 120px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		cursor: pointer;
	}

	.statistics-wrap .item .flex-box:first-child {
		margin-right: 30px;
	}

	.statistics-wrap .flex-box .title {
		font-size: 14px;
	}

	.statistics-wrap .flex-box .num {
		font-size: 28px;
		font-weight: 400;
		color: #FF8143;
		margin-top: 10px;
	}

	.el-main {
		padding: 0;
	}

	.ns-survey-info .layui-card-body .ns-survey-detail-aco {
		font-size: 16px;
		line-height: 18px;
		margin-top: 20px;
		font-weight: 400;
	}

	.ns-prompt-block {
		position: absolute;
		top: 18px;
		right: 20px;
		font-size: 12px;
		cursor: pointer;
	}

	.ns-prompt-block .ns-prompt {
		width: auto;
		height: auto;
		line-height: unset;
	}

	.ns-prompt-block .ns-prompt-box {
		top: 40px;
		left: -53px;
		width: 150px;
		text-align: center;
	}

	.ns-prompt-block .ns-prompt-box:before,
	.ns-prompt-block .ns-prompt-box:after {
		left: 62px;
		top: unset;
	}

	.ns-prompt-block .ns-prompt-box:before {
		border-bottom-color: #e4e4e4;
		border-right-color: transparent;
		top: -25px;
	}

	.ns-prompt-block .ns-prompt-box:after {
		border-bottom-color: #FFFFFF;
		border-right-color: transparent;
		top: -23px;
	}

	.ns-survey-yesterday-btn {
		font-size: 12px;
		width: 44px;
		height: 22px;
		padding: 0;
		line-height: 22px;
	}

	.ns-border-color {
		border-color: #FF6A00 !important;
	}

	.ns-text-color {
		color: #FF6A00 !important;
	}

	.layui-btn-primary {
		border: 1px solid #C9C9C9;
		background-color: #fff;
		color: #555;
	}


	.layui-btn {
		display: inline-block;
		white-space: nowrap;
		text-align: center;
		border-radius: 2px;
		cursor: pointer;
	}
	.ns-card-common {
	    
	    margin-bottom: 0;
	    box-shadow: initial;
	}
	
	.layui-card-header {
	    position: relative;
	    color: #333;
	    border-radius: 2px 2px 0 0;
	    font-size: 14px;
	}
	
	.ns-item-block-parent {
	    
	    width: 100%;
	    display: flex;
	    flex-wrap: wrap;
	    
	    box-sizing: border-box;
	    border-radius: 5px;
	}
	.ns-item-content-title{
		color: #333;
	}
	.ns-item-block-parent .ns-item-block-wrap {
	    padding: 15px;
	    display: flex;
	    justify-content: flex-start;
	    align-items: flex-start;
	}
	.ns-item-block-parent .ns-item-pic {
	    flex-shrink: 0;
	    width: 40px;
	    height: 40px;
	    text-align: center;
	    line-height: 40px;
	    margin-right: 10px;
	}
	.ns-item-block-parent .ns-item-con {
	    overflow: hidden;
	    height: 40px;
	    display: flex;
	    flex-direction: column;
	    justify-content: space-around;
	}
	.ns-item-block-parent .ns-item-con .ns-item-content-title {
	    white-space: nowrap;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    line-height: 20px;
	    font-size: 14px;
	    color: #666;
	}
	.ns-item-block-parent .ns-item-con .ns-item-content-desc {
	    line-height: 20px;
	    font-size: 12px;
	    color: #999;
	}
	.ns-line-hiding {
	    overflow: hidden;
	    text-overflow: ellipsis;
	    display: -webkit-box;
	    -webkit-line-clamp: 1;
	    -webkit-box-orient: vertical;
	    white-space: normal;
	    word-break: break-all;
	}
	.ns-item-block-parent .ns-item-pic img {
	    max-width: 100%;
	    max-height: 100%;
	}
</style>
